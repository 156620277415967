/*
 * @LastEditTime: 2022-08-05 15:10:27
 * @Description: please input some description
 * @FilePath: /dataview-next/src/global/actions/ShowViewData.js
 * @Date: 2022-05-11 19:15:38
 * @Author: lisushuang
 * @LastEditors: lisushuang
 */
/* eslint-disable */
import baseAction from "./baseAction"
import ViewData from "@/views/objects/components/viewData"
import objects from "../../api/objects"
import Vue from 'vue';
import { Button, Message, MessageBox } from "element-ui";

export default class ShowViewData extends baseAction {

  view_uuid = null
  object_uuid = null
  name = null

  constructor() {
    super()
  }

  /**
   * @description: 核心处理方法
   */
  handler() {

    console.log('showviewdata',this.props, this.props.uuid)
    this.getLoading("查询中")

    // 只传了一个view_uuid
    if (this.props && !this.props.uuid) {
      this.view_uuid = this.props
      objects.getOneView(this.view_uuid).then(res => {
        this.loading.close()
        if (res.data.code == 200) {
          let viewData = res.data.data
          this.object_uuid = viewData.object_uuid
          this.name = viewData.name
          this.props = viewData
          this.afterHandler()
        } else {
          this.loading.close()
          this.showError(res.data.msg)
        }

      }).catch(res => this.loading.close())
      return
    }

    // 传进来的是个view数据的对象
    if (this.props.uuid && this.props.object_uuid && this.props.name) {
      this.view_uuid = this.props.uuid
      this.object_uuid = this.props.object_uuid
      this.name = this.props.name
      this.afterHandler()
      return
    }
    this.showError('展示视图数据：错误的参数！')
    return
  }

  afterHandler(){
    if(this.props.is_paging !== undefined && !this.props.is_paging){
      // 没分页提示
      MessageBox.confirm('该视图没有分页，您确定要查看该视图吗？', '提示').then(res => {
        this.loading.close()
        if(res == 'confirm'){
          this.showInstance()
        }
        return 
      }).catch(() => {this.loading.close()})
    }else{
      this.loading.close()
      this.showInstance()
    }
    return
  }

  showInstance(){
    this.showPopDialog(
      this.content,
      '视图数据：' + this.name + ' - - ' + this.view_uuid,
      this.foot,
      true
    )
  }

  content = () => {
    let h = this.mountInstance.$createElement
    return (
      <ViewData
        obuuid={this.object_uuid}
        view_uuid={this.view_uuid}
        style="width:100%;height:100%"
      />
    )
  }

  foot = () => {
    let h = this.mountInstance.$createElement
    return (
      <Button type="danger" onClick={()=>this.closeDialog()}> 关闭 </Button>
    )
  }

}